#PointCloudsAccessRights {
    margin-top: 4rem;
    padding-left: 20px;
    padding-right: 20px;
	padding-bottom: 20px;
}

#PointCloudsAccessRights #PointCloudsAccessRightsTablesWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#PointCloudsAccessRights table {
    width: 48%;
    /* border-spacing: 0; */
    border-collapse: collapse ;
    table-layout: fixed;
    overflow: hidden;
    border-radius: 2px;
}

#PointCloudsAccessRights table th {
    height: 2rem;
    background-color: #607980;
    color: white;
    overflow: hidden;
    text-align: left;
    padding-left: 5px;
}

#PointCloudsAccessRights #PointCloudsAccessRightsUsersTable input[type="radio"],
#PointCloudsAccessRights #PointCloudsAccessRightsPointCloudsTable input[type="checkbox"] {
    display:none;
    margin: 0px;
}

#PointCloudsAccessRights td {
    height: 2rem;
    padding: 0px;
}

#PointCloudsAccessRights label {
    padding: 0px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    text-align: left;
    cursor: pointer;
    padding-left: 5px;
    border: none;
    outline: none;
    user-select: none;
}

#PointCloudsAccessRights label:hover {
    background: beige;
}

#PointCloudsAccessRights input[type="radio"]:checked+label,
#PointCloudsAccessRights input[type="checkbox"]:checked+label {
    /* background-color: #0c4e89; */
    background-color: #0c4f89dc;
    color: white;
}

#PointCloudsAccessRights #publicIcon {
    margin-left: 10px;
}
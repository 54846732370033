#AuthWidget {
    all: unset;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto;
    cursor: pointer;
    user-select: none;
}

#AuthWidget #AuthWidgetLogo {
    font-size: 1.6rem;
    transition: all 0.1s ease;
}

#AuthWidget .isOpenAuthActionsOpen {
    transform: rotate(-90deg);
}

#AuthWidgetActions {
    position: absolute;
    padding: 0;
    margin: 0;
    top: 3rem;
    right: 1rem;
    background-color: #607980;
    font-weight: bold;
    color: white;
    border-radius: 2px;
}

#AuthWidgetActions li {
    display: flex;
    align-items:center;
    height: 2.5rem;
    padding: 0px 20px 0px 20px;
    cursor: pointer;
}

#AuthWidgetActions a {
    all: unset;
}

#AuthWidgetActions li:hover {
    background-color: #6b878f;
}
#usersList {
    padding-left: 20px;
    padding-right: 20px;
	padding-bottom: 20px;
}

#usersList table{
    border-spacing: 0;
    border-collapse: separate ;
    table-layout: fixed;
    overflow: hidden;
    width: 100%;
    border-radius: 2px;
}

#usersList table th {
    height: 2rem;
    background-color: #607980;
    color: white;
    overflow: hidden;
    text-align: left;
    padding-left: 5px;
}

#usersList .userFirstNameTh, 
#usersList .userLastNameTh, 
#usersList .userCompanyTh {
    width: 10rem;
}

#usersList .userEmailTh {
    width: 20rem;
}

#usersList .userPasswordTh {
    width: 6rem;
}

#usersList .userActionsTh {
    width: 4rem;
}

#usersList .userRoleTh {
    width: 8rem;
}

#usersList table td {
    padding-left: 5px;
    height: 2rem;
}

#usersList tr{
    height: 2rem;
    font-size: 1rem;
}

#usersList tr:hover {
    background: beige;
}

#usersList .inputWrapper {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    height: 5rem;
}

#usersList td input, 
#usersList td select {
    box-sizing: border-box;
    height: 1.8rem;
    background-color: #f1f1f1;
    padding-left: 0.5rem;
    border: none;
    border-bottom: solid 2px #f1f1f1;;
    font-family: inherit;
    border-radius: 2px;
    flex-shrink: 0;
}

#usersList td input:focus, 
#usersList td select:focus {
    outline: none;
    border-bottom: solid 2px #607980;
    background-color: #607980;
    color: white;
}

#usersList .validationError {
    border-bottom: solid 2px red !important;
}

#usersList .messageValidationError {
    margin-top: 5px;;
    font-size: 0.8rem;
    color: red;
}

#usersList .userActions {
    display: flex;
    justify-content: space-around;
    height: 2rem;
    font-size: 1.2rem;
}

#usersList #trModifyingUser .userActions {
    margin-top: 1rem;
}

#usersList .userActions svg {
    cursor: pointer;
    height: 2rem;
}

#usersList #userEdit {
    color: black;
}

#usersList #userDelete {
    color: red;
}

#usersList #userSaveEdit {
    color: green;
}

#usersList #userCancelEdit {
    color: orange;
}
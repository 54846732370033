#banner {
    display: flex;
    background-color: #f1f1f1;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-bottom: solid 2px black;
    max-height: 60px;
    min-height: 60px;
}

#banner #menuToggle {
    all: unset;
    cursor: pointer;
}

#burgerCenter {
    width: 30px;
}

#burgerCenter:before,
#burgerCenter:after,
#burgerCenter div {
  background: #607980;
  content: "";
  display: block;
  height: 4px;
  border-radius: 3px;
  margin: 5px 0;
  transition: 0.3s;
}
.burgerIsOpen #burgerCenter:before {
  transform: translateY(9px) rotate(135deg);
}
.burgerIsOpen #burgerCenter:after {
  transform: translateY(-9px) rotate(-135deg);
}
.burgerIsOpen #burgerCenter div {
  transform: scale(0);
}

#banner #logoWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: black;
    text-decoration: none;
    margin-left: 2rem;
    /* border: solid 1px lightcoral; */
}

#banner #companyLogo {
    height: 2.6rem;
    /* border: solid 1px lightcoral; */
}

#banner #companyName {
    margin-left: 0.5rem;
    font-size: 1.8rem;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    font-weight: bold;
    font-family: "Maven Pro", "Raleway", sans-serif;
    /* border: solid 1px lightcoral; */
    /* color: blue; */
}

#banner #companyName::after {
    content: attr(data-text);
    position: relative;
    color: pink;
    top: 5px;
}
#projectsThumbnails {
    padding-left: 20px;
    padding-right: 20px;
	padding-bottom: 20px;
}

#projectsThumbnails ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 2rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#projectsThumbnails ul a:link{
    text-decoration: none;
}

#projectsThumbnails ul li {
    width: 200px;
    height: 300px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    padding: 20px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.65);
    transition: all 0.1s ease;
}

#projectsThumbnails ul li:hover {
    box-shadow: none;
    transform: translate(3px, 3px);
}

#projectsThumbnails .projectsThumbnailsName {
    height: 100%;
    color: white;
    border: solid 2px rgb(96,121,128, 0.7);;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    transition: border 0.2s ease;
}

#projectsThumbnails ul li:hover .projectsThumbnailsName {
    border: solid 2px #ffffff;
}

#projectsThumbnails .projectsThumbnailsInfosWrapper {
    position: relative;
    top: 5rem;
    transition: top 0.2s ease;
    background-color: rgb(96,121,128, 0.7);
}

#projectsThumbnails ul li:hover .projectsThumbnailsInfosWrapper {
    top: 0rem;
}

#projectsThumbnails .projectsThumbnailsInfosWrapper h3{
    margin:0;
    padding-top: 0.5rem;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

#projectsThumbnails .projectsThumbnailsInfosWrapper p{
    margin:0;
    height: 5rem;
    padding-left: 10px;
    padding-top: 5px;
}
#error #errorContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

#error #companyLogoFlipped {
    transform: rotate(190deg);
    width: 15rem;
}

#error #errorContent a{
    color: black;
}
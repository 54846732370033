#home #homeContent {
    padding: 20px;
}

#welcomeParagraphWrapper{
    display: flex;
    align-items:center;
    /* height: 250px; */
    gap: 20px;
    /* border: solid 3px rosybrown; */
}

#home #homeContent img {
    height: 250px;
    /* border: solid 3px paleturquoise; */
}

#home #homeContent #welcomeParagraphWrapper p{
    margin: 0;
    height: fit-content;
    /* border: solid 3px sandybrown; */
}

#home #homeContent #welcomeParagraphWrapper p strong{
    display: block;
    font-size: 2rem;
    margin-bottom: 20px;
}

#home #homeContent #welcomeParagraphWrapper p a{
    color: black;
}

#home #publicProjectsThumbnails ul {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 2rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

#home #publicProjectsThumbnails ul a:link{
    text-decoration: none;
}

#home #publicProjectsThumbnails ul li {
    width: 200px;
    height: 300px;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    padding: 20px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.65);
    transition: all 0.1s ease;
}

#home #publicProjectsThumbnails ul li:hover {
    box-shadow: none;
    transform: translate(3px, 3px);
}

#home #publicProjectsThumbnails .projectsThumbnailsName {
    height: 100%;
    color: white;
    border: solid 2px rgb(96,121,128, 0.7);;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    transition: border 0.2s ease;
}

#home #publicProjectsThumbnails ul li:hover .projectsThumbnailsName {
    border: solid 2px #ffffff;
}

#home #publicProjectsThumbnails .projectsThumbnailsInfosWrapper {
    position: relative;
    top: 5rem;
    transition: top 0.2s ease;
    background-color: rgb(96,121,128, 0.7);
}

#home #publicProjectsThumbnails ul li:hover .projectsThumbnailsInfosWrapper {
    top: 0rem;
}

#home #publicProjectsThumbnails .projectsThumbnailsInfosWrapper h3{
    margin:0;
    padding-top: 0.5rem;
    padding-left: 10px;
    display: flex;
    align-items: center;
}

#home #publicProjectsThumbnails .projectsThumbnailsInfosWrapper p{
    margin:0;
    height: 5rem;
    padding-left: 10px;
    padding-top: 5px;
}
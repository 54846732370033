#navbar {
    box-sizing: border-box;
    flex-shrink: 1;
    max-width: 13rem;
    background-color: #607980;
    font-weight: bold;
    color: white;
    transition: all 0.3s ease;
}

.navBarIsOpen {
    flex-basis: 30%;
    min-width: 10rem;
    flex-grow: 0;
}

.navBarIsClose {
    flex-basis: 0%;
    width: 0px;
    min-width: 0px;
    flex-grow: 0;
}

#navbar ul {
    padding: 0px;
    margin: 0;
    transition: all 0.1s ease;
}

.navBarIsClose ul {
    opacity: 0;
}

#navbar li {
    display: flex;
    align-items:center;
    height: 2.5rem;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
}

#navbar a {
    all: unset;
}

#navbar .navBarIcons {
    padding-right: 8px;
    width: 1.2rem;
}

#navbar .navLinksFolder li {
    display: flex;
    justify-content: space-between;
}

#navbar .navLiFolderOpen{
    background-color: #566c72;
}

#navbar .navNestedLinks {
    background-color: #566c72;
}

#navbar svg {
    transition: all 0.1s ease;
}

#navbar .isOpenAdministrationOpen {
    transform: rotate(-90deg);
}

#navbar li:hover {
    background-color: #6b878f;
}

@media only screen and (max-width: 650px) {
    #navbar { 
        max-width: 100%;
        width: 100%;
    }

    #navbar ul {
        display: inline-block;
        padding: 0px;
        margin: 0;
        transition: all 0.1s ease;
    }

    .navBarIsOpen {
        flex-basis: auto;
        min-width: 10rem;
        flex-grow: 0;
    }

    #navbar ul {
        display: flex;
    }
}
#createUser {
    padding-left: 20px;
    padding-right: 20px;
}

#createUser form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
}

#createUser .inputWrapper {
    box-sizing: border-box;
    height: 5rem;
    min-width: 3rem;
    font-size: 1rem;
    flex: 1 0 10rem;
    display: flex;
    flex-direction: column;
}

#createUser .inputWrapper.buttonAddUserWrapper {
    flex: 1 0 1rem;
}

#createUser .inputWrapper > *:first-child {
    box-sizing: border-box;
    height: 2rem;
    background-color: #f1f1f1;
    padding-left: 0.5rem;
    border: none;
    border-bottom: solid 2px #f1f1f1;
    font-family:inherit;
    border-radius: 2px;
    flex-shrink: 0;
}

#createUser .inputWrapper > *:first-child:focus {
    outline: none;
    border-bottom: solid 2px #607980;
    background-color: #607980;
    color: white;
}

#createUser .validationError {
    border-bottom: solid 2px red !important;
}

#createUser .messageValidationError {
    margin-top: 5px;
    font-size: 0.8rem;
    color: red;
}

#createUser #buttonAddUser {
    background-color: white;
    border-bottom: solid 2px white;
    /* border: solid 2px red; */
}

#createUser #iConAddUser {
    color: green;
    cursor: pointer;
    height: 2rem;
    font-size: 1.2rem;
}
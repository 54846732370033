#createPointCloud {
    padding-left: 20px;
    padding-right: 20px;
}

#createPointCloud form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
}

#createPointCloud .inputWrapper {
    box-sizing: border-box;
    height: 5rem;
    min-width: 3rem;
    font-size: 1rem;
    flex: 1 0 12rem;
    display: flex;
    flex-direction: column;
}

#createPointCloud .inputWrapper.buttonAddPointCloudWrapper,
#createPointCloud .inputWrapper.inputcheckboxWrapper {
    flex: 1 0 1rem;
    user-select: none;
}

#createPointCloud .inputWrapper > *:first-child {
    box-sizing: border-box;
    height: 2rem;
    background-color: #f1f1f1;
    padding-left: 0.5rem;
    border: none;
    border-bottom: solid 2px #f1f1f1;
    font-family:inherit;
    border-radius: 2px;
    flex-shrink: 0;
}

#createPointCloud .inputWrapper.inputcheckboxWrapper > *:first-child {
    background-color: white;
    border-bottom: solid 2px white;
}

#createPointCloud .inputWrapper.inputcheckboxWrapper span {
    line-height: 2rem;
}

#createPointCloud .inputWrapper.inputcheckboxWrapper input, 
#createPointCloud .inputWrapper.inputcheckboxWrapper label {
    cursor: pointer;
}

#createPointCloud .inputWrapper > *:first-child:focus {
    outline: none;
    border-bottom: solid 2px #607980;
    background-color: #607980;
    color: white;
}

#createPointCloud .inputFile {
    opacity: 0; 
    z-index: -1; 
    position: absolute;
}

#createPointCloud .inputFileLabel {
    box-sizing: border-box;
    height: 2rem;
    background-color: #f1f1f1;
    padding-top: 1px;
    padding-left: 0.5rem;
    border: none;
    border-bottom: solid 2px #f1f1f1;
    font-family:inherit;
    border-radius: 2px;
    flex-shrink: 0;
    line-height: 2rem;
    cursor: pointer;
    /* border: solid 1px salmon; */
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

#createPointCloud .inputFileLabel svg {
    height: 1.2rem;
}

#createPointCloud .inputFileLabel:hover {
    outline: none;
    border-bottom: solid 2px #607980;
    background-color: #607980;
    color: white;
}

#createPointCloud .validationError {
    border-bottom: solid 2px red !important;
}

#createPointCloud .messageValidationError {
    margin-top: 5px;
    font-size: 0.8rem;
    color: red;
}

#createPointCloud #buttonAddPointCloud {
    background-color: white;
    border-bottom: solid 2px white;
}

#createPointCloud #iconAddPointCloud {
    color: green;
    cursor: pointer;
    height: 2rem;
    font-size: 1.2rem;
}
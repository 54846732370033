html,
body,
#root {
    height: 100%;
    font-family: "Raleway", sans-serif;
    font-size: 1rem;
    
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #607980 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 15px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #607980;
    border-radius: 10px;
    border: 4px solid #ffffff;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

#main_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
}

.content {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 70%;
    box-sizing: border-box;
    max-height: calc(100vh - 62px);
    overflow: hidden;
}

.contentWrapper {
    width: 100%;
    height: 100%;
    border: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.contentScrollWrapper {
    width: 100%;
    height: calc(100% - 1.8rem);
    border: none;
    overflow: auto;
    margin: 0;
    box-sizing: border-box;
    /* border: solid 3px palevioletred; */
}

.currentRoutePath {
    height: 1.8rem;
    font-weight: normal;
    text-align: left;
    border-bottom: solid 1px #607980;
    margin: 0;
    padding-left: 20px;
    line-height: 1.8rem;
    box-sizing: border-box;
}

.contentLoaderWrapper {
    height: 5rem;
    /* border: solid 3px plum; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentLoader {
    height: 2rem;
    width: 2rem;
    border-bottom: solid 3px #607980;
    border-top: solid 3px #607980;
    border-right: solid 3px #f1f1f1;
    border-left: solid 3px #f1f1f1;
    border-radius: 50%;
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media only screen and (max-width: 650px) {
    #main_wrapper { 
        flex-direction: column;
    }
}
#logIn #logInWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

#logIn #logInWindow {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
    width: 30rem;
    /* Glass */
    background: rgba(96, 121, 128, 0.18);
    border-radius: 2px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.8px);
    -webkit-backdrop-filter: blur(4.8px);
    border: 1px solid rgba(96, 121, 128, 0.08);
}

#logIn .inputWrapper {
    box-sizing: border-box;
    height: 4rem;
    display: flex;
    flex-direction: column;
}

#logIn input {
    flex-shrink: 0;
    box-sizing: border-box;
    height: 2rem;
    background-color: #f1f1f1;
    padding-left: 0.5rem;
    border: none;
    border-bottom: solid 2px #f1f1f1;
    font-family:inherit;
    font-size: 1rem;
    border-radius: 2px;
    margin: 0;
}

#logIn input:focus {
    outline: none;
    background-color: #607980;
    color: white;
}

#logIn .validationError {
    border-bottom: solid 2px red !important;
}

#logIn .messageValidationError {
    margin-top: 5px;
    font-size: 0.8rem;
    color: red;
}

#logIn #wrongCredentials {
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 0.8rem;
    color: red;
}

#logIn button {
    all: unset;
    cursor: pointer;
    background-color: #f1f1f1;
    border-bottom: 20px;
    height: 2rem;
    border-radius: 2px;
    text-align: center;
    border: solid 2px #607980;
    font-weight: bold;
    color: #607980;
}

#logIn .inputWrapper.buttonLogInWrapper {
    height: 3rem;
}

#logIn button:hover {
    background-color: #607980;
    color: white;
}